<template>
  <div class="gg-container">
    <div class="content">
      <div class="content-top">
        机构配置
      </div>
    </div>
    <div class="content-box">
      <div class="row alc block">
        <div class="title">机构：</div>
        <div>
          <el-select
            v-model="info.agent_id"
            size="mini"
            style="width:200px"
            placeholder="请选择机构"
          >
            <el-option
              v-for="item in agentList"
              :key="item.id"
              :label="item.merchant_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">APPID：</div>
        <div>
          <el-input
            v-model="info.app_id"
            placeholder="请输入APPID"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">APP秘钥：</div>
        <div>
          <el-input
            v-model="info.secret"
            placeholder="请输入APP秘钥"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">小程序名：</div>
        <div>
          <el-input
            v-model="info.mini_app_name"
            placeholder="请输入小程序名"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>

      <div class="row alc block">
        <div class="title">使用药品库类型：</div>
        <div>
          <el-select
            v-model="info.use_drug_type"
            size="mini"
            style="width:200px"
            placeholder="请选择药品库类型"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">微信证书路径：</div>
        <div>
          <el-input
            v-model="info.cert_path"
            placeholder="请输入证书路径"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">微信秘钥路径：</div>
        <div>
          <el-input
            v-model="info.key_path"
            placeholder="请输入秘钥路径"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">极速问诊医生：</div>
        <div>
          <el-select
            v-model="info.speed_doctor_id"
            size="mini"
            style="width:200px"
            placeholder="请选择医生"
          >
            <el-option
              v-for="item in doctorOptions"
              :key="item.doctor_id"
              :label="item.doctor_name"
              :value="item.doctor_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">审方药师：</div>
        <div>
          <el-select
              v-model="info.default_phar_id"
              size="mini"
              style="width:200px"
              placeholder="请选择医生"
          >
            <el-option
                v-for="item in phar_list"
                :key="item.id"
                :label="item.doctor_name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">统一运费：</div>
        <div>
          <el-input
            v-model="info.express_fee"
            placeholder="请输入运费"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">短信appID：</div>
        <div>
          <el-input
            v-model="info.sms_app_id"
            placeholder="请输入ID"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">短信appKey：</div>
        <div>
          <el-input
            v-model="info.sms_app_key"
            placeholder="请输入key"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">预约时间间隔(分钟)：</div>
        <div>
          <el-input
            v-model="info.time_during"
            placeholder="请输入时间间隔"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">短信签名：</div>
        <div>
          <el-input
            v-model="info.sms_sign_name"
            placeholder="请输入签名"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">同一时间图文接诊数量：</div>
        <div>
          <el-input
            v-model="info.diag_num"
            placeholder="请输入数量"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">公众号：</div>
        <div>
          <el-input
            v-model="info.official_account"
            placeholder="请填写公众号"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">主题颜色：</div>
        <div>
          <el-color-picker v-model="info.color"></el-color-picker>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">客服电话：</div>
        <div>
          <el-input
            v-model="info.cs_mobile"
            placeholder="请填写客服电话"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">回调地址：</div>
        <div>
          <el-input
            v-model="info.notify_url"
            placeholder="请输入回调地址"
            size="mini"
            class="input"
          ></el-input>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">小程序黑灰主题(特殊纪念日使用)：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.gray_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">医生处方签章：</div>
        <div>
          <el-switch
            active-text="手动"
            class="switch"
            inactive-text="静默"
            :active-value="2"
            :inactive-value="1"
            v-model="info.esign_pres_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">药师处方签章：</div>
        <div>
          <el-switch
              active-text="手动"
              class="switch"
              inactive-text="静默"
              :active-value="2"
              :inactive-value="1"
              v-model="info.phar_pres_sign_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">电子病历签章状态：</div>
        <div>
          <el-switch
            active-text="手动"
            class="switch"
            inactive-text="静默"
            :active-value="2"
            :inactive-value="1"
            v-model="info.esign_emr_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">药材别名状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.drug_alias_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">微信订阅消息状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.subscribe_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">短信配置状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.sms_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">上传监管方式：</div>
        <div>
          <el-switch
              active-text="自动"
              class="switch"
              inactive-text="手动"
              :active-value="2"
              :inactive-value="1"
              v-model="info.upload_type"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">延后审方状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.delay_pres_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">中药自动审方状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.auto_tcm_pres_status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">处方推送患者是否可见：</div>
        <div>
          <el-switch
            active-text="可见"
            class="switch"
            inactive-text="不可见"
            :active-value="1"
            :inactive-value="0"
            v-model="info.tim_status_pres"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">状态：</div>
        <div>
          <el-switch
            active-text="启用"
            class="switch"
            inactive-text="关闭"
            :active-value="1"
            :inactive-value="0"
            v-model="info.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block">
        <div class="title">是否显示拍方抓药：</div>
        <div>
          <el-switch
              active-text="启用"
              class="switch"
              inactive-text="关闭"
              :active-value="1"
              :inactive-value="0"
              v-model="info.pres_take_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <div class="row alc block" style="margin-top:15px;">
        <div class="title">签章：</div>
        <div>
          <customUpload
            :fileUrl="info.seal_url"
            @on-success="(file) => onSuccess(file, 'seal_url')"
          >
          </customUpload>
        </div>
      </div>
      <div class="row alc block" style="margin-top:15px;">
        <div class="title">LOGO：</div>
        <div>
          <customUpload
            :fileUrl="info.qrcode"
            @on-success="(file) => onSuccess(file, 'qrcode')"
          >
          </customUpload>
        </div>
      </div>
      <div class="row alc block" style="margin-top:15px;">
        <div class="title">海报：</div>
        <div>
          <customUpload
            :fileUrl="info.poster_img"
            @on-success="(file) => onSuccess(file, 'poster_img')"
          >
          </customUpload>
        </div>
      </div>
      <div class="row alc" style="margin-top:30px;">
        <div class="title">订阅消息：</div>
        <div style="display: flex;flex-direction: column;">
          <div
            class="row"
            style="align-items:flex-start;margin-bottom:10px"
            v-for="item in info.subscribe"
            :key="item.value"
          >
            <el-input
              v-model="item.sub_name"
              placeholder="请输入模块名"
              size="mini"
              class="input"
              style="margin-right:15px;"
            ></el-input>
            <el-input
              v-model="item.sub_key"
              placeholder="请输入模块"
              size="mini"
              class="input"
            ></el-input>
            <div style="width:100px" @click="_add(item.value)">添加</div>
            <div style="color:red;width:100px" @click="_del(item.value)">
              删除
            </div>
          </div>
        </div>
      </div>
      <div class="row alc block" style="width:100%;height:400px;margin-top:30px;align-items: flex-start;">
        <div class="title">封面选择：</div>
        <div class="row">
          <el-radio v-model="info.thesis_type" :label="1" style="display: flex;align-items: flex-start;">
            <el-image :src="url1" style="width: 150px; height: 300px"></el-image>
          </el-radio>
          <el-radio v-model="info.thesis_type" :label="2" style="display: flex;align-items: flex-start;">
            <el-image :src="url2" style="width: 150px; height: 300px"></el-image>
          </el-radio>
        </div>
      </div>
    </div>

    <div style="width:100%;text-align: right;margin-top:10px !important;">
      <el-button type="primary" size="mini" @click="_ok">保 存</el-button>
      <el-button size="mini" @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {
  postAgentSetAdd,
  putAgentSetEdit,
  getAgentSetDoctorSelect,
  getAgentSetPharmacistSelect,
} from "@/api/systemManage";
import { getAdminCommonMerchants } from "@/api/finance/index.js";
import customUpload from "@/components/customUpload";
export default {
  name: "elDrawer",
  props: {
    drawerVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "add",
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    customUpload,
  },
  data() {
    return {
      phar_list:[],//药师数组
      url1:require('../../../assets/common.jpg'),
      url2:require('../../../assets/yzt.jpg'),
      doctorOptions: [],
      options: [
        {
          name: "公共药品库",
          value: 1,
        },
        {
          name: "自有药品库",
          value: 2,
        },
      ], //药品类型选择
      agentList: [], //机构列表
    };
  },
  watch: {
    //监视info.agent_id的变化
    "info.agent_id": function(val) {
      let agent_name = this.agentList.find((item) => item.id == val)
        .merchant_name;
      this.info.agent_name = agent_name;
      let data = {
        agent_id: val,
      };
      getAgentSetDoctorSelect(data).then((res) => {
        if (res.code == 200) {
          this.doctorOptions = res.data;
        }
      });
      console.log("info.agent_id", this.info.agent_name, val);
    },
    type: function(val) {
      console.log("type", val);
      if (val == "edit") {
        console.log("编辑");
      }
    },
  },

  created() {
    console.log(this.info, this.type);
    if (this.type == "edit") {
      let data = {
        agent_id: this.info.agent_id,
      };
      getAgentSetDoctorSelect(data).then((res) => {
        if (res.code == 200) {
          this.doctorOptions = res.data;
        }
      });
      if (this.info.subscribe.length == 0) {
        this.info.subscribe.push({ sub_key: "", sub_name: "", value: 0 });
      }
    }
    //获取药师
    getAgentSetPharmacistSelect().then(res=>{
      if(res.code==200){
        this.phar_list = res.data;
      }
    })
    this._getAdminCommonMerchants();
  },
  methods: {
    cancel() {
      this.$emit("closeDrawer", false);
    },
    _getAdminCommonMerchants() {
      console.log("getAgentSetSelect");
      getAdminCommonMerchants().then((res) => {
        if (res.code == 200) {
          this.agentList = res.data;
        }
      });
    },
    _add(row) {
      console.log(row);
      this.info.subscribe.push({ sub_key: "", sub_name: "", value: row + 1 });
    },
    _del(row) {
      if (this.info.subscribe.length == 1) {
        this.$message({
          message: "至少保留一条订阅消息",
          type: "warning",
        });
        return;
      }
      this.info.subscribe.splice(row, 1);
    },
    _ok() {
      console.log("info", this.info);
      if (this.type == "edit") {
        putAgentSetEdit(this.info).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.$emit("closeDrawer", false);
          }
        });
      } else {
        postAgentSetAdd(this.info).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.$emit("closeDrawer", false);
          }
        });
      }
    },
    onSuccess(file, type, name) {
      console.log("file", file, this.info[type], name);
      this.info[type] = file;
    },
    closeDrawer() {
      this.$emit("closeDrawer", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .content-top {
    font-size: 24px;
    padding-bottom: 6px;
    font-weight: bold;
    width: 100rpx !important;
    border-bottom: 3px solid #25aed8;
  }
}
.row {
  display: flex;
}
.alc {
  align-items: center;
}
.content-box {
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 10px;
  display: flex;
  //   flex-direction: row;
  flex-wrap: wrap;
  //   align-items:flex-start;
  //   justify-content: baseline;
  max-height: 80vh;
  overflow-y: auto;
  .block {
    width: 400px;
    height: 50px;
    .title {
      min-width: 80px;
    }
  }
}
.input {
 ::v-deep .el-input__inner {
    height: 30px;
    width: 200px;
  }
}
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
